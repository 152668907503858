/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal, { Styles } from 'react-modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import { motion } from 'framer-motion';
import { oxygenTtamayoContext } from '../../context';
import { EnrollmentModalProps } from '../../interfaces';
import { useEnrollmentForm } from '../../hooks/useEnrollmentForm';
import { useTrackingEvents } from '../../utils/tracking';
import { MemoizedTextInput } from './FormInputs/TextInput';
import { MemoizedPhoneInput } from './FormInputs/PhoneInput';
import { MemoizedSubmitButton } from './FormInputs/SubmitButton';
import { useScrollLock } from '../../hooks/useScrollLock';

const modalStyles: Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },
  content: {
    position: 'relative' as const,
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '500px',
    width: '100%',
    margin: '0 auto',
    padding: '2rem',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: 'white',
    maxHeight: 'calc(100vh - 2rem)',
    overflow: 'auto',
    outline: 'none',
  },
};

export const EnrollmentModal: React.FC<EnrollmentModalProps> = ({
  isOpen,
  onClose,
  persistRegistration = false,
}) => {
  const { cursoData } = useContext(oxygenTtamayoContext);
  const { getAllTrackingParameters, trackConversion } = useTrackingEvents();
  const [isRegistered, setIsRegistered] = useState(false);

  const initialFormData = useMemo(() => ({
    name: '',
    whatsapp: '',
    email: '',
    product_id: cursoData?.product_id ?? 0,
    product_name: cursoData?.product_name ?? '',
    opted_in_newsletter: true,
  }), [cursoData?.product_id, cursoData?.product_name]);

  const {
    formData,
    errors,
    touched,
    isSubmitting,
    attemptedSubmit,
    handleInputChange,
    handleBlur,
    validateAllFields,
    isFormValid,
    setIsSubmitting,
    setAttemptedSubmit,
    resetForm,
  } = useEnrollmentForm(initialFormData);

  useScrollLock(isOpen);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (!isOpen && !persistRegistration) {
      setIsRegistered(false);
    }
  }, [isOpen, persistRegistration]);

  const handleDisabledClick = () => {
    setAttemptedSubmit(true);
    validateAllFields();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) {
      handleDisabledClick();
      return;
    }

    setIsSubmitting(true);

    try {
      const trackingData = getAllTrackingParameters();
      const submissionData = {
        ...formData,
        ...trackingData,
        submission_timestamp: new Date().toISOString(),
        page_url: window.location.href,
      };

      const webhookUrl = cursoData?.api?.external?.lp_n8n_webhook;
      if (!webhookUrl) {
        throw new Error('N8N webhook URL is missing in configuration');
      }

      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) throw new Error('Submission failed');

      await trackConversion({
        formData: submissionData,
        eventName: 'Lead',
      });

      setIsRegistered(true);
      setTimeout(() => {
        onClose();
        resetForm();
      }, 3000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Formulario de inscripción"
      ariaHideApp={false}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      className="outline-none"
    >
      <div className="relative">
        <button
          onClick={onClose}
          className="absolute -top-3 -right-3 w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center hover:bg-gray-50 transition-colors duration-200 z-10"
          aria-label="Cerrar modal"
        >
          <svg
            className="w-4 h-4 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.3 }}
        >
          {isRegistered ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="text-center py-8"
            >
              <svg
                className="w-16 h-16 mx-auto text-green-500 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <h2 className="text-2xl font-bold mb-2">¡Gracias por registrarte!</h2>
              <p className="text-gray-600">
                Nos pondremos en contacto contigo pronto.
              </p>
            </motion.div>
          ) : (
            <>
              <h2 className="text-2xl font-bold mb-6 text-center">Regístrate para la clase muestra</h2>
              <form onSubmit={handleSubmit} className="space-y-4" noValidate>
                <MemoizedTextInput
                  id="name"
                  name="name"
                  label="Nombre completo"
                  value={formData.name}
                  onChange={(value) => handleInputChange('name', value)}
                  onBlur={() => handleBlur('name')}
                  error={errors.name}
                  touched={touched.name}
                  attemptedSubmit={attemptedSubmit}
                  autoComplete="name"
                />

                <MemoizedPhoneInput
                  value={formData.whatsapp}
                  onChange={(value) => handleInputChange('whatsapp', value)}
                  onBlur={() => handleBlur('whatsapp')}
                  error={errors.whatsapp}
                  touched={touched.whatsapp}
                  attemptedSubmit={attemptedSubmit}
                />

                <MemoizedTextInput
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={(value) => handleInputChange('email', value)}
                  onBlur={() => handleBlur('email')}
                  error={errors.email}
                  touched={touched.email}
                  attemptedSubmit={attemptedSubmit}
                  autoComplete="email"
                />

                <div aria-labelledby="newsletter-label">
                  <label
                    id="newsletter-label"
                    htmlFor="newsletter"
                    className="flex items-center gap-2 cursor-pointer mt-2 mb-4"
                  >
                    <input
                      id="newsletter"
                      name="newsletter"
                      type="checkbox"
                      checked={formData.opted_in_newsletter}
                      onChange={() => handleInputChange('opted_in_newsletter', !formData.opted_in_newsletter)}
                      aria-checked={formData.opted_in_newsletter}
                      className="w-4 h-4 rounded border-gray-300 text-[#65bec2] focus:ring-[#65bec2]"
                    />
                    <span className="text-gray-700 font-NeueMontreal text-[clamp(14px,2vw,16px)]">
                      Suscribirme a la newsletter
                    </span>
                  </label>
                </div>

                <MemoizedSubmitButton
                  isValid={isFormValid()}
                  isSubmitting={isSubmitting}
                  onClick={(e) => handleSubmit(e)}
                  onDisabledClick={handleDisabledClick}
                />
              </form>
            </>
          )}
        </motion.div>
      </div>
    </Modal>
  );
};
