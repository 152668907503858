import React from 'react';

import {FaFacebookSquare} from '@react-icons/all-files/fa/FaFacebookSquare';
import {IoLogoTiktok} from '@react-icons/all-files/io5/IoLogoTiktok';
import {GrInstagram} from '@react-icons/all-files/gr/GrInstagram';
import {FaTwitter} from '@react-icons/all-files/fa/FaTwitter';
import {FaYoutube} from '@react-icons/all-files/fa/FaYoutube';

export const SocialIcons = () => {
  return (
    <div className="tt-header__social-icons opacity-40 hover:opacity-100 transition-opacity">
      <a href="https://facebook.com/ttamayocom" target="_blank" rel="noreferrer" className="tt-header__social-icons__facebook">
        <FaFacebookSquare/>
      </a>
      <a href="https://instagram.com/ttamayocom" target="_blank" rel="noreferrer" className="tt-header__social-icons__instagram">
        <GrInstagram />
      </a>
      <a href="https://twitter.com/ttamayocom" target="_blank" rel="noreferrer" className="tt-header__social-icons__twitter">
        <FaTwitter />
      </a>
      <a href="https://www.tiktok.com/@ttamayocom" target="_blank" rel="noreferrer" className="tt-header__social-icons__tiktok">
        <IoLogoTiktok />
      </a>
      <a href="https://www.youtube.com/ttamayocom" target="_blank" rel="noreferrer" className="tt-header__social-icons__tiktok">
        <FaYoutube />
      </a>
    </div>
  );
};