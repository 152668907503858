import React from 'react';
import { useBravePopupsScaling } from '../../hooks/useBravePopupsScaling';

export const BravePopupsScaling: React.FC = () => {
  // The hook handles all the scaling logic
  useBravePopupsScaling();

  // This component doesn't need to render anything
  return null;
};

export default BravePopupsScaling;
