import { useState, useCallback, useRef } from 'react';
import { EnrollmentFormData, EnrollmentFormErrors, ValidatedFields } from '../interfaces';
import { validateField } from '../utils/validation';

export const useEnrollmentForm = (initialData: EnrollmentFormData) => {
  const [formData, setFormData] = useState<EnrollmentFormData>(initialData);
  const [errors, setErrors] = useState<EnrollmentFormErrors>({});
  const [touched, setTouched] = useState<Record<ValidatedFields, boolean>>({
    name: false,
    whatsapp: false,
    email: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const formDataRef = useRef(formData);
  formDataRef.current = formData;

  const handleInputChange = useCallback((field: keyof EnrollmentFormData, value: string | boolean) => {
    setFormData(prev => ({ ...prev, [field]: value }));

    if (errors[field as ValidatedFields]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field as ValidatedFields];
        return newErrors;
      });
    }
  }, [errors]);

  const handleBlur = useCallback((fieldName: ValidatedFields) => {
    setTouched(prev => ({ ...prev, [fieldName]: true }));

    const error = validateField(fieldName, formDataRef.current[fieldName]);
    if (error) {
      setErrors(prev => ({ ...prev, [fieldName]: error }));
    }
  }, []);

  const validateAllFields = useCallback(() => {
    const newErrors: EnrollmentFormErrors = {};
    let isValid = true;

    (['name', 'whatsapp', 'email'] as const).forEach((field) => {
      const error = validateField(field, formDataRef.current[field]);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  }, []);

  const isFormValid = useCallback(() => (
    Object.keys(errors).length === 0 &&
    formDataRef.current.name.trim() !== '' &&
    formDataRef.current.whatsapp.trim() !== '' &&
    formDataRef.current.email.trim() !== ''
  ), [errors]);

  const resetForm = useCallback(() => {
    setFormData(initialData);
    setErrors({});
    setTouched({
      name: false,
      whatsapp: false,
      email: false,
    });
    setIsSubmitting(false);
    setAttemptedSubmit(false);
  }, [initialData]);

  return {
    formData,
    errors,
    touched,
    isSubmitting,
    attemptedSubmit,
    handleInputChange,
    handleBlur,
    validateAllFields,
    isFormValid,
    setIsSubmitting,
    setAttemptedSubmit,
    resetForm,
  };
};
