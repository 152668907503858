import React from 'react';
import { NextUIProvider } from "@nextui-org/react";
import { HeaderRow } from './HeaderRow';
import { Logo } from './Logo';
import { theme } from '../../nextui-theme';
import { globalStyles } from '../../global-styles';
import { oxygenTtamayoContext } from '../../context';

export const SimpleHeader = () => {
  globalStyles();

  return (
    <NextUIProvider theme={theme} disableBaseline>
      <oxygenTtamayoContext.Provider value={window.oxygenTtamayo}>
        <div className="py-4 md:py-6 lg:py-8">
          <HeaderRow
            center={
              <div className="w-auto mx-auto"> {/* Ensure container wraps tightly around the logo */}
                <Logo className="w-32 md:w-40 lg:w-48" withLink={false} /> {/* Pass responsive width classes and withLink prop */}
              </div>
            }
            className="max-w-[200px] md:max-w-[240px] lg:max-w-[280px] mx-auto"
          />
        </div>
      </oxygenTtamayoContext.Provider>
    </NextUIProvider>
  );
}
