import React from 'react';
import { Parser } from 'expr-eval';
import { Shortcode } from '../../interfaces';
const parser = new Parser();

type AdSpaceProps = {
  conditionalVariables: any;
  conditionalContent: Shortcode[];
  wrapperClass?: string;
  id: string;
  index: number;
  total: number;
};

export const AdSpace = ({
  conditionalVariables,
  conditionalContent,
  wrapperClass,
  id,
  index,
  total,
}: AdSpaceProps) => {
  const variables = {
    ...conditionalVariables,
    content_index: index,
    content_count: total,
    is_last: index === total - 1,
    is_first: index === 0,
    is_odd: index % 2 === 1,
    is_even: index % 2 === 0,
  };

  // Find the first shortcode that matches the conditional expression in priority order (highest first)
  // if it has no content then continue to the next one
  const adContent = conditionalContent
    .sort((a, b) => b.priority - a.priority)
    .find((shortcode) => {
      if (!shortcode.expression) {
        return true;
      }
      const expression = parser.parse(shortcode.expression);
      return expression.evaluate(variables);
    })?.content;

  return adContent ? (
    <div className={wrapperClass} id={id}>
      {adContent && <div dangerouslySetInnerHTML={{ __html: adContent }} />}
    </div>
  ) : null;
}
