// 1. Import `createTheme`
import { createTheme, NextUIProvider, Text } from "@nextui-org/react"

// 2. Call `createTheme` and pass your custom values
export const theme = createTheme({
  type: "light",
  theme: {
    colors: {
      background: "#fafafa",
      backgroundAlpha: "rgba(250, 250, 250, 0.90)",
      secondary: "#3232C8",
      primary: "#0064F0",
      //   // brand colors
      //   primaryLight: '$green200',
      //   primaryLightHover: '$green300',
      //   primaryLightActive: '$green400',
      //   primaryLightContrast: '$green600',
      //   primary: '#4ADE7B',
      //   primaryBorder: '$green500',
      //   primaryBorderHover: '$green600',
      //   primarySolidHover: '$green700',
      //   primarySolidContrast: '$white',
      //   primaryShadow: '$green500',
      // gradient: 'linear-gradient(112deg, rgb(50 50 200) -20%, rgb(0 100 240) 50%)',
      gradient: 'linear-gradient(112deg, rgb(0 25 55) -25%, rgb(50 50 200) -10%, rgb(0 100 240) 80%)',
      //   link: '#5E1DAD',
      //   // you can also create your own color
      //   myColor: '#ff4ecd'
      //   // ...  more colors
    },
    space: {},
    fonts: {
      sans: [
        'NeueMontreal',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ].join(', '),
      mono: "Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono'"
    }
  }
});
