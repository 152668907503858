import React, { useEffect, useState, useContext, useRef } from 'react';
import { parseSrcset } from 'srcset';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowSize } from "@react-hook/window-size";
import { useElementSize, useIntersectionObserver } from 'usehooks-ts'
import useMergedRef from '@react-hook/merged-ref';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ColorTranslator } from 'colortranslator';
import { FastAverageColorResult } from 'fast-average-color';

import { appStateContext } from '../../context';
import { debounce } from '../../utils';
import { loadedImage, BackgroundImageProps } from '../../interfaces';

export const BackgroundImage = (props: BackgroundImageProps) => {
  const { placeholder, className, srcset, defaultBackgroundImg, backgroundGradient, children, defaultWidth, innerContainerClasses, innerContainerStyle, setFrameColor, outerContainerStyle, frameDarken, hasFrame, deCorsify, ...rest } = props;

  const { loadedImages } = useContext(appStateContext);
  const hdImage = useRef<HTMLDivElement>(null);
  const [windowWidth, _windowHeight] = useWindowSize();
  const [elWidthRef, { width: elWidth, height: _elHeight }] = useElementSize<HTMLDivElement>();

  const [loadedImageWidth, setLoadedImageWidth] = useState(0);
  const [isFirstVisible, setIsFirstVisible] = useState(false);
  const [hadFrame, setHadFrame] = useState<boolean | undefined>(false);

  const entry = useIntersectionObserver(hdImage, {})
  const isVisible = !!entry?.isIntersecting

  // Determine the container proportion to use for calculating the appropriate image size
  // if the element hasn't been rendered yet, the proportion is 1
  const containerProportion = (elWidth ?? defaultWidth ?? windowWidth) / windowWidth;

  // Parse the srcset string into an array of SrcSetDefinition objects if srcset is provided
  const srcsetArray = srcset ? parseSrcset(srcset) : undefined;
  const sortedSrcsetArray = srcsetArray?.sort((a, b) => (a.width ?? 0) - (b.width ?? 0));

  // among all the images in the array, get the smallest image that is larger than the window width. if none are larger, return the last image in the array
  const getMatchingImage = () => {
    const filteredArray = sortedSrcsetArray?.filter(image => image.width && image.width > (windowWidth * containerProportion) + 0);
    return filteredArray?.[0] || sortedSrcsetArray?.[sortedSrcsetArray.length - 1];
  };

  const getMatchingImageFromLoadedImages = (src: string) =>
    loadedImages?.find(img => img.url === src) ?? undefined;

  const addImageToLoadedImages = (imgData: loadedImage) => {
    if (!getMatchingImageFromLoadedImages(imgData.url)) {
      loadedImages.push({
        url: imgData.url,
        width: imgData.width,
        height: imgData.height,
        average_color: imgData.average_color,
        is_inline: imgData.is_inline || false,
      });
    }
  }

  // dont modify is_inline nor the src, which acts as the key
  // modify the original object in the loadedImages array
  const modifyLoadedImage = (imgData: loadedImage) => {
    const image = getMatchingImageFromLoadedImages(imgData.url);
    if (image) {
      const index = loadedImages.indexOf(image);
      loadedImages.splice(index, 1, imgData);
    }
  }

  const setImgBackground = (el: HTMLDivElement, imgData: loadedImage) => {
    const backgroundImages = [
      backgroundGradient,
      `url('${imgData.url}')`,
      placeholder && `url('${placeholder}')`,
    ].filter(Boolean).join(', ');

    const backgroundSizes = [
      backgroundGradient && "auto",
      `cover`,
      placeholder && "auto",
    ].filter(Boolean).join(', ');

    el.setAttribute('style', [
      `background-image: ${backgroundImages};`,
      `background-size: ${backgroundSizes};`,
    ].join(' '));
    addImageToLoadedImages(imgData);
    setLoadedImageWidth(imgData.width ?? 0);
  }

  const getImgToLoad = () => {
    const matchingImage = getMatchingImage();
    return matchingImage || defaultBackgroundImg;
  }

  const doLoad = () => {
    const hdImageRef = hdImage.current!;
    const imgToLoad = getImgToLoad();
    setImgBackground(hdImageRef, imgToLoad);
  }

  useEffect(() => {
    // i prefer to trigger the load when the image is first visible instead of if it isn't loaded because it fires exactly once
    if (isVisible && !isFirstVisible) {
      setIsFirstVisible(true);
      doLoad();
    }
  }, [isVisible, isFirstVisible]);

  const deCorsUrl = (url: string) => {
    return `https://corsproxy.io/?${encodeURIComponent(url)}`;
  }

  const getDarkenedColor = (color: FastAverageColorResult, lum: number): string | undefined => {
    if (color?.hex) {
      const c = new ColorTranslator(color?.hex);
      const hslaString = `hsla(${c.H}, ${c.S}%, ${lum * 100}%, ${c.A})`;
      return hslaString;
    } else {
      return undefined;
    }
  };

  const _setFrameColor = (color: FastAverageColorResult) => {
    if (setFrameColor) {
      if (frameDarken) {
        setFrameColor(getDarkenedColor(color, frameDarken))
      } else {
        setFrameColor(color?.hex);
      }
    }
  }

  const handleHasFrameChange = () => {
    if (hasFrame) {
      const _imgToLoad = getImgToLoad();
      const _loadedImage = getMatchingImageFromLoadedImages(_imgToLoad.url);
      const averageBgdColor = _loadedImage?.average_color;
      if (averageBgdColor) {
        _setFrameColor(averageBgdColor);
      } else {
        import(/* webpackChunkName: "fac" */'fast-average-color')
          .then(({ FastAverageColor }) => {
            const fac = new FastAverageColor();
            fac.getColorAsync(deCorsify ? deCorsUrl(_imgToLoad.url) : _imgToLoad.url)
              .then(color => {
                const newImgToLoad = getImgToLoad();
                const newLoadedImg = getMatchingImageFromLoadedImages(newImgToLoad.url);
                if (newLoadedImg?.url) {
                  modifyLoadedImage({
                    url: newLoadedImg.url,
                    average_color: color,
                  });
                } else {
                  addImageToLoadedImages({
                    url: newImgToLoad.url,
                    width: newImgToLoad.width,
                    average_color: color,
                  });
                }
                _setFrameColor(color);
              });
          });
      }
      setHadFrame(hasFrame);
    }
    // was previously loaded, but now has no frame, is missing gradient
    if (!hasFrame && hadFrame) {
      doLoad();
    }
  }

  // Update the background image when the window width changes
  const handleResize = () => {
    if (sortedSrcsetArray?.length) {
      const matchingImage = getMatchingImage();

      // Only update the style property of the current element with the new image url if the new image is larger than the one currently loaded
      if (matchingImage?.width && (matchingImage?.width > loadedImageWidth)) {
        doLoad();
      }
    }
  };

  const debouncedHandleResize = debounce(handleResize, 500);

  useEffect(() => {
    handleHasFrameChange();
  }, [hasFrame]);

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [windowWidth, containerProportion, elWidth, loadedImageWidth]);

  return (
    <div
      ref={useMergedRef(elWidthRef, hdImage)}
      style={outerContainerStyle}
      className={[
        `bg-center bg-cover w-full`,
        className || '',
      ].join(' ')}
      {...rest}
    >
      <div
        style={innerContainerStyle}
        className={[
          `flex flex-col items-center`,
          innerContainerClasses ?? '',
        ].join(' ')}>
        {children}
      </div>
    </div>
  );
};
