import { useEffect } from 'react';

export const useEnrollmentModal = (isLandingPageMode: boolean, setIsModalOpen: (open: boolean) => void) => {
  useEffect(() => {
    if (!isLandingPageMode) return;

    const handleClick = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      setIsModalOpen(true);
    };

    const chatModal = document.querySelectorAll('.bot-widget-bubble');
    // Hide the chat modal
    chatModal.forEach(element => {
      (element as HTMLElement).style.display = 'none';
    });

    // Brave popup elements - only embedded versions
    const bravePopups = document.querySelectorAll('.bravepopup_embedded');

    // Contact button (desktop and mobile versions)
    const contactButtons = document.querySelectorAll(`
      #link_button-3175-21172,
      [data-id="link_button-3175-21172"],
      a[href*="link_button-3175-21172"]
    `);

    // Select all <a> tags that aren't within React components
    const nonReactLinks = document.querySelectorAll('a:not([data-reactroot] a):not([class*="react"]):not([class*="chakra"])');

    // Combine all elements that should trigger the modal
    const elements = [
      ...Array.from(bravePopups),
      ...Array.from(contactButtons),
      ...Array.from(nonReactLinks)
    ];

    // Add both click and touchend events for better mobile handling
    elements.forEach(element => {
      element.addEventListener('click', handleClick, { passive: false });
      element.addEventListener('touchend', handleClick, { passive: false });
    });

    return () => {
      elements.forEach(element => {
        element.removeEventListener('click', handleClick);
        element.removeEventListener('touchend', handleClick);
      });
    };
  }, [isLandingPageMode, setIsModalOpen]);
};
