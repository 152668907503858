import React, {useContext} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PostType } from '../../interfaces';
import { appStateContext } from '../../context';
import { srcsetSizes } from '../../helpers/srcset-sizes';

export const ArchiveInfinitePostItem = ({index, data, width}: {index: number, data: PostType, width: number}) => {
  const { loadedImages } = useContext(appStateContext);
  const imgWidth = data.featured_image_full_size?.width ?? 0;
  const imgHeight = data.featured_image_full_size?.height ?? 0;
  const srcset_sizes = srcsetSizes({defaultImgWidth: imgWidth, imgWidth: width, srcSet: data.featured_image_srcset});

  return (
    <div className="rounded overflow-hidden shadow-lg hover:shadow-2xl" key={data.id}>
      {data.featured_image_srcset_img && <a href={data.read_more_link}>
        <LazyLoadImage
          className="w-full h-auto"
          srcSet={data.featured_image_srcset || undefined}
          sizes={srcset_sizes || undefined}
          src={!data.featured_image_srcset ? data.featured_image_urls.find(image => image.size === "medium")?.url : undefined}
          effect="blur"
          placeholderSrc={ data.featured_image_lqip ? data.featured_image_lqip : undefined }
          alt={data.title}
          width={width}
          height={width * imgHeight / imgWidth}
          // if the image has been loaded before, it won't be lazy loaded
          // eslint-disable-next-line react/jsx-no-bind
          afterLoad={() => loadedImages.push({
            url: data.featured_image_srcset_img,
            width: imgWidth,
            height: imgHeight,
            is_inline: false,
          })}
          visibleByDefault={loadedImages.some(image => image.url === data.featured_image_srcset_img)}
        />
      </a>}
      <div className="px-6 pt-4 pb-2">
        <a style={{
          fontFamily: "EditorialNew, serif",
        }} className="font-medium text-xl mb-2 text-armada-de-prusia" href={data.read_more_link}>{data.title}</a>
        <p className="text-gray-700 text-sm leading-relaxed"
          style={{
            WebkitLineClamp: 6,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          dangerouslySetInnerHTML={{__html: data.excerpt}} />
      </div>
      {/* {data.tags && data.tags.length > 0 && <div className="px-6 pb-4">
        {data.tags.map(tag => (
          <a key={tag.slug} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-normal text-gray-700 mr-2 mb-2 hover:bg-gray-300 lowercase" href={`/${tag_base}/${tag.slug}/`}>{tag.name}</a>
        ))}
      </div>} */}
      <div className=" w-full flex justify-between px-6 pb-6 text-gray-700 text-sm font-normal">
        <span className='uppercase text-xs tracking-widest'>{data.date}</span>
        <a className='uppercase text-xs tracking-widest hover:underline text-armada-de-prusia' href={data.read_more_link}>Leer más »</a>
      </div>
    </div>
  );
}