import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { motion } from 'framer-motion';
import { SubmitButtonProps } from '../../../interfaces';

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  isValid,
  isSubmitting,
  onClick,
  onDisabledClick,
}) => {
  return (
    <motion.button
      type="button"
      onClick={isValid ? onClick : onDisabledClick}
      disabled={isSubmitting}
      className={`
        w-full 
        text-center 
        py-4 
        px-6 
        rounded-lg 
        font-NeueMontreal 
        !tracking-wider 
        uppercase
        transition-colors 
        duration-300
        text-[clamp(14px,2vw,16px)] 
        !important
        ${isValid ? '[&]:!bg-amarillo-boton [&]:hover:!bg-[#e6bc00] !text-indigo-japones font-bold' : 'bg-gray-100 text-gray-400 hover:bg-gray-200 border border-gray-500'}
      `}
      whileHover={isValid ? {
        scale: 1.02,
        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1)'
      } : {
        scale: 1
      }}
      animate={isValid ? {
        y: [0, -4, 0],
        transition: {
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }
      } : {
        opacity: [1, 0.7, 1],
        transition: {
          duration: 2,
          repeat: Infinity,
          ease: 'easeInOut'
        }
      }}
      whileTap={isValid ? { scale: 0.98 } : { scale: 1 }}
      aria-disabled={!isValid || isSubmitting}
      aria-label={isSubmitting ? 'Registrando...' : 'Registrarme'}
    >
      {isSubmitting ? 'Registrando...' : 'REGISTRARME'}
    </motion.button>
  );
};

// Memoize the component for better performance
export const MemoizedSubmitButton = React.memo(SubmitButton);
