import React, { useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Waypoint } from 'react-waypoint';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga';

import { appStateContext } from '../../context';
import { PostType, ShortcodeLocation, seenSinglePostItem } from '../../interfaces';
import { resetTitleAndUrl } from '../../helpers/reset-title-and-url';
import { SplitPost } from './SplitPost';
import { SplitPostTemplate } from './SplitPostTemplate';
import { RelatedPosts } from './RelatedPosts';
import { FeaturedImage } from './FeaturedImage';
import { MobileShare } from './MobileShare';
import { DesktopShare } from './DesktopShare';
import { AdSpace } from './AdSpace';

export const SinglePostItem = ({index, data, width}: {index: number, data: PostType, width: number}) => {
  const { enteredSinglePostItems, seenSinglePostItems } = useContext(appStateContext);
  const { isDevelopment } = useContext(appStateContext);

  // replace development urls in post content
  const contentReplacementRegex = new RegExp(`https://${window.location.hostname}/wp-content/uploads`, 'g');
  data.post_content_filtered = isDevelopment ? data.post_content_filtered.replace(contentReplacementRegex, 'https://www.ttamayo.com/wp-content/uploads') : data.post_content_filtered;
  // filter the shortcode locations to get the in_content_master shortcode location, use the first one, all are the same
  const inContentMaster = data.shortcode_locations.filter((shortcodeLocation: ShortcodeLocation) => shortcodeLocation.in_content);
  const inContentRepeatTimes = inContentMaster.length && inContentMaster[0].repeat_times;
  const inContentRepeatEach = inContentMaster.length && inContentMaster[0].repeat_each;
  // repeat times is the number of times to repeat the ad, but splitInto is the number of times to split the content into
  const inContentSplitInto = inContentRepeatTimes && inContentRepeatTimes > 0 ? inContentRepeatTimes + 1 : undefined;
  const [frameColor, setFrameColor] = useState<string | undefined>(undefined);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1400px)' });

  const adSpaces = data?.shortcode_locations || [];
  const aboveFeaturedImageAd = adSpaces.find((adSpace: ShortcodeLocation) => adSpace.id === "above_featured_image");
  const beforeContentAd = adSpaces.find((adSpace: ShortcodeLocation) => adSpace.id === "before_content");
  const afterContentAd = adSpaces.find((adSpace: ShortcodeLocation) => adSpace.id === "after_content");

  const registerPageViewOnce = (item: seenSinglePostItem) => {
    // the first item already has a pageview registered by wordpress, so only register a pageview if the item is not in the array and the index is not 0
    if (!seenSinglePostItems.some((i) => i.index === item.index) && (index !== 1)) {
      ReactGA.pageview(item.url);
      seenSinglePostItems.push(item);
    }
  };

  const handleEnter = () => {
    enteredSinglePostItems.push({
      index,
      title: data.title,
      url: data.read_more_link,
    });
    // if you scroll down and then scroll up to the top of the page all items will be removed from the array, so if the only item in the array is the current item, then reset the title and url
    if (enteredSinglePostItems.length === 1) {
      resetTitleAndUrl(data.title + " | ttamayo.com", data.read_more_link, true, true);
    }
  };

  const handleLeave = () => {
    // remove the item with the index that matches the current index
    enteredSinglePostItems.splice(enteredSinglePostItems.findIndex((enteredSinglePostItem) => enteredSinglePostItem.index === index), 1);
    // get the first item in the array which should be the last entered item, that means the user has not scrolled past it yet, so set the title and url to that item
    const firstEnteredSinglePostItem = enteredSinglePostItems[0];
    if (firstEnteredSinglePostItem) {
      resetTitleAndUrl(firstEnteredSinglePostItem.title + " | ttamayo.com", firstEnteredSinglePostItem.url, true, true);
      registerPageViewOnce(firstEnteredSinglePostItem);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Waypoint onEnter={handleEnter} onLeave={handleLeave} key={"post-" + data.guid}>
      <div className='tt-single__wrapper'>
        {aboveFeaturedImageAd && (
          <AdSpace
            id={aboveFeaturedImageAd.id}
            conditionalVariables={data}
            conditionalContent={aboveFeaturedImageAd.shortcodes}
            index={index}
            total={1}
          />
        )}
        <div className="tt-single__featured-image-container w-full" style={{
          backgroundColor: frameColor,
        }}>
          <div className="tt-single__container">
            {/* {data.has_post_thumbnail && ( */}
            <FeaturedImage
              data={data}
              index={index}
              width={width}
              setFrameColor={setFrameColor}
              frameColor={frameColor}
              hasFrame={isLargeScreen}
              frameDarken={0.25}
            />
            {/* )} */}
          </div>
        </div>
        {beforeContentAd && (
          <AdSpace
            id={beforeContentAd.id}
            conditionalVariables={data}
            conditionalContent={beforeContentAd.shortcodes}
            index={index}
            total={1}
          />
        )}
        <div className="tt-single__container">
          <div className="justify-between items-stretch lg:flex">
            <aside style={{
              width: '32px',
              paddingLeft: '32px',
              marginRight: '-32px',
            }} className="hidden flex-none lg:block mt-8">
              <div className="sticky top-40">
                <DesktopShare data={data} />
              </div>
            </aside>
            <div className="lg:flex-grow">
              <MobileShare data={data} />
              <SplitPost
                content={data.post_content_filtered}
                Template={SplitPostTemplate}
                splitRegex={/<\/p>/g}
                joinString="</p>"
                splitInto={inContentSplitInto}
                splitEvery={inContentRepeatEach || undefined}
                data={data} />
            </div>
          </div>
        </div>
        {afterContentAd && (
          <AdSpace
            id={afterContentAd.id}
            conditionalVariables={data}
            conditionalContent={afterContentAd.shortcodes}
            index={index}
            total={1}
          />
        )}
        {data.related_posts && data.related_posts.length > 0 &&
          <RelatedPosts posts={data.related_posts} />
        }
      </div>
    </Waypoint>
  );
};

export default SinglePostItem;