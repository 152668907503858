import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { motion, AnimatePresence } from 'framer-motion';
import { TextInputFieldProps } from '../../../interfaces';

export const TextInput: React.FC<TextInputFieldProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  touched,
  attemptedSubmit,
  type = 'text',
  autoComplete,
}) => {
  const showError = error && (touched || attemptedSubmit);

  return (
    <div className="flex flex-col gap-1">
      <label
        htmlFor={id}
        className="!text-sm !text-gray-600 !font-NeueMontreal !font-normal !m-0"
      >
        {label}
      </label>

      <input
        id={id}
        type={type}
        name={name}
        autoComplete={autoComplete}
        placeholder=" "
        value={value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        aria-label={label}
        aria-required="true"
        aria-invalid={showError ? 'true' : 'false'}
        aria-describedby={showError ? `${id}-error` : undefined}
        className={`
          w-full 
          px-3 
          !h-[42px] 
          rounded 
          border 
          ${showError ? 'border-red-500 bg-red-50' : 'border-gray-200'} 
          font-NeueMontreal 
          focus:outline-none 
          focus:border-gray-300 
          text-[clamp(14px,2vw,16px)]
          transition-colors
          duration-200
        `}
      />

      <AnimatePresence>
        {showError && (
          <motion.span
            id={`${id}-error`}
            role="alert"
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ duration: 0.2 }}
            className="text-red-500 text-sm mt-0.5"
          >
            {error}
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  );
};

// Memoize the component for better performance
export const MemoizedTextInput = React.memo(TextInput);
