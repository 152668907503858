/* eslint-disable react/jsx-no-bind */
import React, {useContext, FC} from 'react';
import {oxygenTtamayoContext} from '../../context';
import {nestedObjectToArray} from '../../utils';

import {Navbar, Dropdown, Button} from "@nextui-org/react";

interface ChildItem {
  id?: string;
  slug?: string;
  title: string;
  url: string;
}

type NavigationMenuProps = {
  setBlogMegaMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  blogMegaMenuIsOpen: boolean,
}

export const NavigationMenu: FC<NavigationMenuProps> = (props) => {
  const {main_menu, blog_url} = useContext(oxygenTtamayoContext);
  const mainMenu = nestedObjectToArray(main_menu, "children");
  const defaultKey: string = "default";

  const pluralNamesNavigationItems = {
    "Blog": "Ver todos los artículos",
    "Cursos y clases": "Ver todos los cursos y clases",
    "Quiénes somos": "Acerca de nosotros",
    "Productos": "Ver todos los productos",
  }

  const toggleBlogMegaMenu = () => {
    props.setBlogMegaMenuIsOpen(!props.blogMegaMenuIsOpen);
  }

  const pluralTitle = (title: keyof typeof pluralNamesNavigationItems) => {
    return pluralNamesNavigationItems[title] || `Ver todos los ${title}`;
  }

  return (
    <>
      {mainMenu.map((i) => {
        const isActive =
          window.location.href === i.url ||
          (i.children.length > 0 && i.children.some((c: any) => c.url === window.location.href));

        if (i.children.length > 0) {
          return (
            <Dropdown isBordered key={i.id || i.url}>
              <Navbar.Item
                isActive={isActive}
              >
                <Dropdown.Button
                  auto
                  light
                  css={{
                    px: 0,
                    dflex: "center",
                    svg: { pe: "none" },
                  }}
                  // iconRight={icons.chevron}
                  ripple={false}
                >{i.title}</Dropdown.Button>
              </Navbar.Item>
              <Dropdown.Menu
                onAction={(kd) => {
                  if (kd === defaultKey) {
                    window.location.href = i.url;
                  } else {
                    const childItem = i.children?.find((child: ChildItem) => child.id === kd || child.slug === kd || child.title === kd);
                    if (childItem?.url) {
                      window.location.href = childItem.url;
                    } else {
                      // eslint-disable-next-line no-console
                      console.error(`Unable to find URL for key: ${kd}`);
                    }
                  }
                }}
                aria-label={i.title}
                css={{
                  $$dropdownMenuWidth: "340px",
                  $$dropdownItemHeight: "40px",
                  "& .nextui-dropdown-item": {
                    py: "$4",
                    // dropdown item left icon
                    svg: {
                      color: "$secondary",
                      mr: "$4",
                    },
                    // dropdown item title
                    "& .nextui-dropdown-item-content": {
                      w: "100%",
                      // fontWeight: "$semibold",
                    },
                  },
                }}
              >
                <Dropdown.Item key={defaultKey}>
                  {pluralTitle(i.title)}
                </Dropdown.Item>
                {i.children?.map((ci: ChildItem) => (
                  <Dropdown.Item key={ci.id ?? ci.slug ?? ci.title}>
                    {ci.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        }

        if (i.url === blog_url) {
          return (
            <Button
              auto
              key={i.id || i.url}
              shadow={props.blogMegaMenuIsOpen ? true : undefined}
              bordered
              color="gradient"
              onClick={toggleBlogMegaMenu}
            >
              {i.title}
            </Button>
          );
        }

        return (
          <Navbar.Link
            key={i.id || i.url}
            isActive={isActive}
            href={i.url}
          >
            {i.title}
          </Navbar.Link>
        );
      })}
    </>
  );
};
