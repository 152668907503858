import React from 'react';
import { PostType } from '../../interfaces';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useElementSize } from 'usehooks-ts';
import { srcsetSizes } from '../../helpers/srcset-sizes';

export const RelatedPost = ({post}: {post: PostType}) => {
  const [imgRef, { width, height: _height }] = useElementSize();
  const srcset_sizes = srcsetSizes({imgWidth: width, srcSet: post.featured_image_srcset, defaultImgWidth: 600});
  return (
    <div key={"related-" + post.id} className="tt-single__related-post overflow-hidden" ref={imgRef}>
      <a
        href={post.read_more_link}
        title={post.title}
      >
        <LazyLoadImage
          wrapperClassName="tt-single__related-post__image-wrapper aspect-w-4 aspect-h-3 w-full"
          className="w-full object-cover"
          srcSet={post.featured_image_srcset || undefined}
          sizes={srcset_sizes || undefined}
          src={post.featured_image_srcset ? undefined : post.featured_image_urls.find(image => image.size === "medium")?.url}
          effect="blur"
          placeholderSrc={ post.featured_image_lqip ? post.featured_image_lqip : undefined }
          alt={post.title} />
      </a>
      <div className="px-2 pt-2">
        <a
          href={post.read_more_link}
          title={post.title}
        >
          <h4
            className="font-normal text-xl text-gray-700"
            style={{fontFamily: "EditorialNew, serif"}}
          >
            {post.title}
          </h4>
        </a>
        <p
          className="text-gray-700 text-sm font-normal leading-relaxed"
          style={{
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          dangerouslySetInnerHTML={{__html: post.excerpt}} />
        <a
          href={post.read_more_link}
          title={post.title}
        >
          <p className="uppercase tracking-widest font-semibold text-pink-900 leading-relaxed text-xxs">
            Leer más &gt;
          </p>
        </a>
      </div>
    </div>
  )
}
