import React from 'react';
import { useLandingPageMode } from '../../hooks/useLandingPageMode';
import { SimpleHeader } from './SimpleHeader';
import { FullHeader } from './FullHeader';

const Header = () => {
  const isLandingPageMode = useLandingPageMode();

  return isLandingPageMode ? <SimpleHeader /> : <FullHeader />;
};

export default Header;
