/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { PhoneInput as ReactPhoneInput } from 'react-international-phone';
import { motion, AnimatePresence } from 'framer-motion';
import { PhoneInputFieldProps } from '../../../interfaces';
import 'react-international-phone/style.css';

export const PhoneInput: React.FC<PhoneInputFieldProps> = ({
  value,
  onChange,
  onBlur,
  error,
  touched,
  attemptedSubmit,
}) => {
  const showError = error && (touched || attemptedSubmit);

  return (
    <div className="flex flex-col gap-1">
      <label htmlFor="whatsapp" className="!text-sm !text-gray-600 !font-NeueMontreal !font-normal !m-0">
        WhatsApp
      </label>

      <ReactPhoneInput
        defaultCountry="mx"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{
          name: 'whatsapp',
          autoComplete: 'tel',
          'aria-required': 'true',
          'aria-invalid': showError ? 'true' : 'false',
          'aria-describedby': showError ? 'whatsapp-error' : undefined
        }}
        style={{
          '--react-international-phone-border-radius': '4px',
          '--react-international-phone-border-color': '#e5e7eb',
          '--react-international-phone-background-color': 'white',
          '--react-international-phone-height': '42px',
          '--react-international-phone-text-color': '#374151',
          '--react-international-phone-font-size': 'clamp(14px, 2vw, 16px)',
          '--react-international-phone-dropdown-item-height': '36px',
          '--react-international-phone-country-selector-padding': '8px 12px',
          '--react-international-phone-padding': '0',
          '--react-international-phone-input-gap': '0',
        } as React.CSSProperties}
        className={`font-NeueMontreal 
          ${showError ? '[&_input]:!bg-red-50 [&_input]:!border-red-500' : ''} 
          [&>div]:!w-full [&_input]:!w-full [&_input]:!text-[clamp(14px,2vw,16px)] 
          [&_.selected-flag]:!h-[42px] [&_.flag-dropdown]:!h-[42px] 
          [&_.country-list]:!max-h-[200px] [&_.country-list]:!bg-white [&_.country-list]:!shadow-lg 
          [&_.selected-flag]:!pl-3 [&_.selected-flag]:!pr-2 [&_input]:!pl-2 
          [&_.country-list_li]:!bg-white [&_.country-list_li:hover]:!bg-gray-100 
          [&_.flag-dropdown]:!w-[70px] [&_.phone-input]:!ml-0 [&_.phone-input]:!flex-1 
          [&_.react-international-phone-country-selector]:!w-[70px] [&>div]:!flex [&>div]:!items-center 
          [&>div>div]:!w-[70px] [&_.country-list]:!top-[42px] [&_.country-list]:!mt-0`}
      />

      <AnimatePresence>
        {showError && (
          <motion.span
            id="whatsapp-error"
            role="alert"
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ duration: 0.2 }}
            className="text-red-500 text-sm mt-0.5"
          >
            {error}
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  );
};

export const MemoizedPhoneInput = React.memo(PhoneInput);
