import React from 'react';
import { PostType } from '../../interfaces';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export const MobileShare = ({
  data,
}: {
  data: PostType | undefined;
}) => {
  return (
    data?.read_more_link ? <div className="mt-8 mx-4 lg:hidden flex flex-row justify-start items-center">
      <div className="max-w-3xl w-full mx-auto">
        <FacebookShareButton
          className='mr-2'
          url={data?.read_more_link}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          className='mr-2'
          url={data?.read_more_link}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <EmailShareButton
          className='mr-2'
          url={data?.read_more_link}>
          <EmailIcon size={32} round />
        </EmailShareButton>
        <TelegramShareButton
          className='mr-2'
          url={data?.read_more_link}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <WhatsappShareButton
          className='mr-2'
          url={data?.read_more_link}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
      : null);
};