import {useEffect, useState, useRef, MutableRefObject} from 'react';

export const objToArr = (obj: any) => {
  return Object.entries(obj).map(([k, v]) => v);
}

// deep flatten an object from certain depth onwards, in selected key in order, add a depth property for the flattened items
export const flattenNavigationItemsFromLevel = (items: any[], fromLevel: number, {childrenKey}: {childrenKey: string}, currentLevel: number = 0) => {
  let result: any[] = [];
  for (const item of items) {
    item.depth = currentLevel;
    if (item[childrenKey].length > 0) {
      if (currentLevel >= fromLevel) {
        result.push({...item, [childrenKey]: []});
        result = result.concat(flattenNavigationItemsFromLevel(item[childrenKey], fromLevel, {childrenKey}, currentLevel + 1));
      } else {
        result.push({...item, [childrenKey]: flattenNavigationItemsFromLevel(item[childrenKey], fromLevel, {childrenKey}, currentLevel + 1)});
      }
    } else {
      result.push(item);
    }
  }
  return result;
}

type keyValues = Record<string, string>[];

// remove object from containing array of objects if they contain a property that matches any of the properties in keyValues, iterate through nested objects in selected key
export const removeObjectsByKeyValues = (obj: any[], nestingKey: string, keyValues: keyValues) => {
  const result: any[] = [];
  for (const item of obj) {
    if (item[nestingKey].length > 0) {
      result.push({...item, [nestingKey]: removeObjectsByKeyValues(item[nestingKey], nestingKey, keyValues)});
    } else {
      let remove = false;
      for (const keyValue of keyValues) {
        for (const [key, value] of Object.entries(keyValue)) {
          if (item[key] === value) {
            remove = true;
          }
        }
      }
      if (!remove) {
        result.push(item);
      }
    }
  }
  return result;
}

export const categoryUrlHasChildren = (items: any[], url: string, {urlKey, childrenKey}: { urlKey: string; childrenKey: string; nameKey?: string; }): boolean => {
  return items.some(i => {
    if (i[urlKey] === url) {
      return i[childrenKey].length > 0;
    }
    return categoryUrlHasChildren(i[childrenKey], url, {urlKey, childrenKey});
  });
}

// get plural name of navigation item if it exists
export const getPluralName = (
  name: string | number,
  defaultPluralName: string,
  pluralNamesNavigationItems: Record<string | number, string>
) => {
  return pluralNamesNavigationItems[name] ?? defaultPluralName;
}

export const replaceKeysNested = <T extends Record<string, any> | any[]>(obj: T, keysMap: { [x: string]: string }): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceKeysNested(item, keysMap)) as T;
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce<Record<string, any>>((acc, key) => {
      const replacedKey = keysMap[key] || key;
      acc[replacedKey] = replaceKeysNested(obj[key], keysMap);
      return acc;
    }, {}) as T;
  }
  return obj;
}

// nest an object inside another object in selected key
export const nestObjInSelectedKey = <T extends Record<string, any>>(
  obj: T,
  {keyMatch, valueMatch}: { keyMatch: string; valueMatch: string; },
  {keyToInsert, objToInsert}: { keyToInsert: string; objToInsert: unknown; }
): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      nestObjInSelectedKey(item, {keyMatch, valueMatch}, {keyToInsert, objToInsert})
    ) as unknown as T;
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object' && value !== null && value[keyMatch] === valueMatch) {
          return [key, { ...value, [keyToInsert]: objToInsert }];
        }
        return [key, value];
      })
    ) as T;
  }
  return obj;
}

// alter property of object in array if a property of the object matches a condition, pass a function to alter the object containing the property, iterate through nested arrays of objects inside the nestingKey of the object and alter the property of the nested objects
export const alterObjProperty = <T extends Record<string, any>>(
  obj: T[] | T,
  nestingKey: string,
  { valueCondition }: { valueCondition: (arg0: any) => boolean },
  { keyToAlter, alterFunction }: { keyToAlter: string; alterFunction: (arg0: any) => any }
): T[] | T => {
  const processItem = (item: T): T => {
    if (valueCondition(item)) {
      return { ...item, [keyToAlter]: alterFunction(item) };
    }
    return {
      ...item,
      [nestingKey]: alterObjProperty(item[nestingKey], nestingKey, { valueCondition }, { keyToAlter, alterFunction })
    };
  };

  if (Array.isArray(obj)) {
    return obj.map(processItem);
  }

  if (typeof obj === 'object' && obj !== null) {
    return processItem(obj);
  }

  return obj;
};

// https://stackoverflow.com/a/70129805
export const findByProperty = <T extends object>(obj: T, predicate: (value: any) => boolean): any => {
  if (predicate(obj)) return obj;
  for (const n of Object.values(obj).filter(Boolean).filter(v => typeof v === 'object')) {
    const found: ReturnType<typeof findByProperty> = findByProperty(n, predicate);
    if (found) return found;
  }
}

// https://usehooks.com/useHover/
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  useEffect(
    () => {
      const node: any = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      } else {
        return () => {
          // do nothing
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

// https://stackoverflow.com/a/47003904
export const queryDirectChildren = (parent: HTMLElement | null, selector: string) => {
  const nodes = parent?.querySelectorAll(selector);
  const filteredNodes = [].slice.call(nodes).filter((n: Element) =>
    (n.parentNode as Element)?.closest(selector) === (parent as Element)?.closest(selector)
  );
  return filteredNodes;
}

// https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940?permalink_comment_id=4276799#gistcomment-4276799
export const nestedObjectToArray = (nestedObject: object, nestingKey: string): any[] => {
  // Use Object.values() to get an array of the nested objects,
  // then use Array.map() to transform each object into a new object
  return Object.values(nestedObject).map(obj => ({
    // Copy the properties from the original object
    ...obj,
    // If the object has nested objects, recursively convert them to an array
    // and assign them to the object's nested key property
    [nestingKey]: obj[nestingKey] ? nestedObjectToArray(obj[nestingKey], nestingKey) : [],
  }));
}

export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor: number,
) => {
  let timeout: NodeJS.Timeout
  const debounced = (...args: Parameters<F>) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), waitFor)
  }
  return debounced
}
