import React, { useContext, useRef } from 'react';
import {
  useMasonry,
  usePositioner,
  useContainerPosition,
  useResizeObserver,
  useScroller,
  useInfiniteLoader
} from "masonic";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowSize } from "@react-hook/window-size";
import { oxygenTtamayoContext } from '../../context';
import { ArchiveInfinitePostItem } from './ArchiveInfinitePostItem';
import { useInfinitePosts } from '../../hooks/useInfinitePosts';

const Archive = () => {
  const containerRef = React.useRef(null);
  const [windowWidth, height] = useWindowSize();
  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    height
  ]);
  const positioner = usePositioner({ width, columnGutter: 16, columnWidth: 300});
  const { scrollTop, isScrolling } = useScroller(offset);
  const resizeObserver = useResizeObserver(positioner);

  const { posts_per_page, query } = useContext(oxygenTtamayoContext);

  const { posts, noMorePosts, fetchMoreItems } = useInfinitePosts({
    query: {
      posts_per_page: +posts_per_page,
      ...query
    },
  });

  const maybeLoadMore = useRef(useInfiniteLoader(fetchMoreItems, {
    isItemLoaded: (index, items) => !!items[index],
    totalItems: noMorePosts ? posts.length : Infinity,
    minimumBatchSize: +posts_per_page,
    threshold: Math.ceil(+posts_per_page * 0.65),
  }));

  return (
    <div className="masonic-container min-h-screen w-full pt-6">
      <div className="masonic w-full mx-auto px-6" style={{
        maxWidth: '1400px',
      }}>
        {useMasonry({
          resizeObserver,
          positioner,
          scrollTop,
          isScrolling,
          height,
          containerRef,
          items: posts,
          overscanBy: 2,
          render: ArchiveInfinitePostItem,
          onRender: maybeLoadMore.current,
        })}
      </div>
    </div>
  );
};

export default Archive;