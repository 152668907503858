import React, { useContext } from 'react';
import { oxygenTtamayoContext } from '../../context';
import LogoSVG from "../../../../img/logo-ttamayo-horizontal.svg";

interface LogoProps {
  className?: string;
  withLink?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ className = "", withLink = true }) => {
  const { home_url } = useContext(oxygenTtamayoContext);

  const logoElement = <LogoSVG className={`h-auto ${className}`} />;

  return withLink ? (
    <a className="tt-header__logo tt-link flex items-center justify-center" href={home_url} target="_self">
      {logoElement}
    </a>
  ) : logoElement;
}
