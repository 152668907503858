import React, { useState, useContext } from 'react';
// import { start } from 'repl';
import { oxygenTtamayoContext } from '../context';
import { oxygenTtamayoInfiniteScrollRequest } from '../interfaces';

export interface UseInfinitePostsProps {
  query: any;
}

export const useInfinitePosts = ({
  query,
}: UseInfinitePostsProps) => {
  const {infinite_scroll_initial_elements} = useContext(oxygenTtamayoContext);
  const [posts, setPosts] = useState(infinite_scroll_initial_elements);
  const [noMorePosts, setNoMorePosts] = useState(!posts || posts.length < query.posts_per_page);

  const [loadedPostItemsIndexes, setLoadedPostItemsIndexes] = useState([] as {startIndex: number, endIndex: number}[]);

  const fetchArchivePosts = async (paged: number) => {
    const modifiedQuery = {
      paged,
      ...query
    };
    const stringifiedQuery = JSON.stringify(modifiedQuery)
    const queryUrlEncoded = encodeURIComponent(stringifiedQuery);
    const rawResponse = await fetch(`/wp-json/oxygen-ttamayo/v1/infinite-scroll-posts?query=${queryUrlEncoded}`);
    const response: oxygenTtamayoInfiniteScrollRequest = await rawResponse.json();
    return response;
  };

  const fetchMoreItems = async (startIndex: number, endIndex: number) => {
    const newIndex = {endIndex, startIndex};
    const existing = loadedPostItemsIndexes.find((el) => {
      return el.startIndex === startIndex && el.endIndex === endIndex;
    });
    const paged = startIndex === 1 ? 1 : Math.ceil(endIndex / query.posts_per_page);
    if (existing) return;
    setLoadedPostItemsIndexes(prev => [...prev, newIndex]);
    try {
      const response = await fetchArchivePosts(paged);
      if (response.data?.length === 0) {
        setNoMorePosts(true);
        return;
      } else {
        setPosts(prevPosts => [...prevPosts, ...response.data]);
      }
    } catch (e) {
      setNoMorePosts(true);
    }
  };

  return {
    posts,
    noMorePosts,
    fetchMoreItems
  }
};