/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState, useMemo } from 'react';
import { EnrollmentModal } from './EnrollmentModal';
import { useEnrollmentModal } from '../../hooks/useEnrollmentModal';
// eslint-disable-next-line import/no-extraneous-dependencies
import { motion } from 'framer-motion';

const CursosSidebar: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Memoize the modal component
  const enrollmentModal = useMemo(() => (
    <EnrollmentModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      persistRegistration={false}
    />
  ), [isModalOpen]);

  // Add this effect to update the CSS variable
  React.useEffect(() => {
    const updateSidebarHeight = () => {
      const sidebarElement = containerRef.current;
      if (sidebarElement) {
        const height = sidebarElement.offsetHeight;
        document.documentElement.style.setProperty('--tt-mobile-sidebar-height', `${height}px`);
      }
    };

    // Update on mount and window resize
    updateSidebarHeight();
    window.addEventListener('resize', updateSidebarHeight);

    return () => {
      window.removeEventListener('resize', updateSidebarHeight);
      document.documentElement.style.removeProperty('--tt-mobile-sidebar-height');
    };
  }, []);

  React.useEffect(() => {
    const sidebarElement = document.getElementById('tt-cursos-sidebar');
    if (sidebarElement) {
      if (isModalOpen) {
        sidebarElement.classList.add('modal-open');
      } else {
        sidebarElement.classList.remove('modal-open');
      }
    }
  }, [isModalOpen]);

  // Hook to handle Brave popups and contact buttons
  useEnrollmentModal(true, setIsModalOpen);

  // If there's no curso data, don't render anything
  if (!window.oxygenTtamayo?.cursoData) {
    return null;
  }

  const {
    price,
    recurrencia,
    nivel,
    modalidad,
    duracion,
    incluye,
    grupos
  } = window.oxygenTtamayo.cursoData;

  const handleEnrollClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  return (
    <div ref={containerRef}>
      <div className="bg-marina-lapislazuli p-6 rounded-t-[10px] shadow-[0_-2px_25px_rgba(22,22,22,0.25)]">
        <h2 className="text-white text-center text-xl mb-4" style={{ fontFamily: 'Roboto' }}>
          Obtén una ¡
          <motion.span
            className="font-bold uppercase"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.2,
              ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            clase gratis
          </motion.span>
          !
        </h2>
        <motion.button
          onClick={handleEnrollClick}
          className="w-full text-center py-2 px-4 bg-amarillo-boton hover:bg-[#e6bc00] transition-colors rounded-lg font-semibold"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.6,
            delay: 0.3,
            ease: "easeOut"
          }}
          whileHover={{
            scale: 1.02,
            transition: { duration: 0.2 }
          }}
          whileTap={{
            scale: 0.98,
            transition: { duration: 0.1 }
          }}
        >
          <span className="text-indigo-japones font-bold tracking-widest uppercase leading-none" style={{ fontFamily: 'Montserrat' }}>
            Registrarme
          </span>
        </motion.button>
      </div>
      {/* hide on mobile */}
      <div className="desktop:p-6 hidden desktop:block">
        <div className="text-left">
          <div className="space-y-4">
            <div>
              <p className="leading-none font-medium mb-2 mt-0">Costos e inversión:</p>
              <div className="grid gap-y-1">
                <div className="flex items-center">
                  <div className="w-5 h-5 flex-shrink-0">
                    <svg className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="ml-2 flex items-center flex-wrap">
                    <span className="font-medium mr-1">Precio:</span>
                    <span dangerouslySetInnerHTML={{ __html: price }} />
                    <span className="ml-1">MXN</span>
                    <span className="text-xs uppercase tracking-wider ml-1">({recurrencia})</span>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="w-5 h-5 flex-shrink-0">
                    <svg className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                  </div>
                  <div className="ml-2">
                    <span className="font-medium">Descuento: </span>
                    <span className="text-xs uppercase tracking-wider">
                      10% para estudiantes y maestros
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p className="leading-none font-medium mb-2">Detalles del programa:</p>
              {nivel && (
                <div className="flex items-center justify-start mb-1">
                  <div className="flex items-center justify-start w-5 h-5">
                    <svg className="h-5 w-5 text-base-dark" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="12" y1="20" x2="12" y2="10" />
                      <line x1="18" y1="20" x2="18" y2="4" />
                      <line x1="6" y1="20" x2="6" y2="16" />
                    </svg>
                  </div>
                  <span className="ml-2 font-medium leading-normal">Nivel:</span>
                  <span className="ml-1 leading-normal">{nivel}</span>
                </div>
              )}
              {modalidad && (
                <div className="flex items-center justify-start mb-1">
                  <div className="flex items-center justify-start w-5 h-5">
                    <svg className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                    </svg>
                  </div>
                  <span className="ml-2 font-medium leading-normal">Modalidad:</span>
                  <span className="ml-1 leading-normal">{modalidad}</span>
                </div>
              )}
              {duracion && (
                <div className="flex items-center justify-start mb-1">
                  <div className="flex items-center justify-start w-5 h-5">
                    <svg className="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <span className="ml-2 font-medium leading-normal">Duración:</span>
                  <span className="ml-1 leading-normal">{duracion}</span>
                </div>
              )}

              {incluye && incluye.length > 0 && (
                <>
                  <p className="leading-none font-medium mb-2 mt-4">El programa incluye:</p>
                  {incluye.map((item, index) => (
                    <div key={`${item.text}-${index}`} className="flex items-center justify-start mb-1">
                      <div className="flex items-center justify-start w-5 h-5" dangerouslySetInnerHTML={{ __html: item.icon }} />
                      <span className="ml-2 leading-normal">{item.text}</span>
                    </div>
                  ))}
                </>
              )}

              {grupos && grupos.length > 0 && grupos.some(grupo => grupo.trim()) && (
                <>
                  <p className="leading-none font-medium mb-2 mt-4">Grupos disponibles:</p>
                  <ul className="list-disc mt-2">
                    {grupos
                      .filter(grupo => grupo.trim())
                      .map((grupo, index) => (
                        <li key={`${grupo}-${index}`} className="text-neutral-700 leading-normal mb-1">
                          {grupo}
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {enrollmentModal}
    </div>
  );
};

export default CursosSidebar;
