import React from 'react';
import Modal from 'react-modal';

import { SearchInput } from './SearchInput';

export interface SearchModalProps {
  searchIsOpen: boolean;
  setSearchIsOpen: (value: boolean) => void;
  handleSearchChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
}

export const SearchModal = ({
  searchIsOpen,
  setSearchIsOpen,
  handleSearchChange,
  searchValue,
}: SearchModalProps) => {
  return (
    <Modal
      isOpen={searchIsOpen}
      // eslint-disable-next-line react/jsx-no-bind
      onRequestClose={() => setSearchIsOpen(false)}
      contentLabel="Search Modal"
      preventScroll
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      closeTimeoutMS={300}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
        content: {
          overflow: "initial",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          padding: "0",
          backgroundColor: "transparent",
        },
      }}
    >
      <div className="tt-modal__content">
        <SearchInput
          autoFocus
          handleSearchChange={handleSearchChange}
          searchValue={searchValue}
          color="secondary"
          size='xl'
          css={{
            "@xs": {
              w: "100%",
              jc: "center",
            },
          }}
        />
      </div>
    </Modal>
  )
}
