// eslint-disable-next-line import/no-extraneous-dependencies
import FontFaceObserver from 'fontfaceobserver';
import { loadedFont } from '../interfaces';

// https://github.com/vercel/next.js/issues/512#issuecomment-322026199
const loadFont = (font: loadedFont) => {
  const link = document.createElement('link');
  link.href = font.url;
  link.rel = 'stylesheet';

  document.head.appendChild(link);

  const fontObserver = new FontFaceObserver(font.name);

  fontObserver.load().then(() => {
    document.body.classList.add(font.slug);
  });
}

export default loadFont;