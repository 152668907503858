import { ValidatedFields } from '../interfaces';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateField = (fieldName: ValidatedFields, value: string): string | undefined => {
  if (!value || value.trim() === '') {
    return 'Este campo es requerido';
  }

  switch (fieldName) {
  case 'name':
    if (value.length < 2) {
      return 'El nombre debe tener al menos 2 caracteres';
    }
    // if (!/^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value)) {
    //   return 'El nombre solo puede contener letras';
    // }
    break;

  case 'whatsapp':
    if (!isPhoneValid(value)) {
      return 'Ingresa un número de WhatsApp válido';
    }
    break;

  case 'email':
    if (!isEmailValid(value)) {
      return 'Ingresa un correo electrónico válido';
    }
    break;

  default:
    return undefined;
  }

  return undefined;
};

export const validateForm = (fields: Record<ValidatedFields, string>): Record<ValidatedFields, string | undefined> => {
  // Initialize errors with all fields set to undefined
  const errors: Record<ValidatedFields, string | undefined> = Object.fromEntries(
    Object.keys(fields).map(key => [key, undefined])
  ) as Record<ValidatedFields, string | undefined>;

  (Object.keys(fields) as ValidatedFields[]).forEach((fieldName) => {
    const error = validateField(fieldName, fields[fieldName]);
    if (error) {
      errors[fieldName] = error;
    }
  });

  return errors;
};