import "./sass/main.scss";
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from './ts/helpers/error-boundry';
import { isLandingPage } from './ts/hooks/useLandingPageMode';

// Always loaded components
const Header = lazy(() => import('./ts/components/Header/Header'));
const Archive = lazy(() => import('./ts/components/Archive/Archive'));
const Single = lazy(() => import('./ts/components/Single/Single'));

// Header mount point
const headerContainer = document.querySelector('.tt-header');
if (headerContainer !== null) {
  const headerRoot = createRoot(headerContainer as HTMLElement);
  headerRoot.render(
    <ErrorBoundary>
      <Suspense fallback={<div>Loading Header...</div>}>
        <Header />
      </Suspense>
    </ErrorBoundary>
  );
}

// Landing page specific components are loaded on demand
const loadLandingPageFeatures = async () => {
  // console.log('Loading landing page features...');

  try {
    const [
      { default: CursosSidebar },
      { default: BravePopupsScaling },
      phoneNumberLib
    ] = await Promise.all([
      import(
        /* webpackChunkName: "landing" */
        /* webpackMode: "lazy" */
        './ts/components/CursosSidebar/CursosSidebar'
      ),
      import(
        /* webpackChunkName: "landing" */
        /* webpackMode: "lazy" */
        './ts/components/BravePopups/BravePopupsScaling'
      ),
      import(
        /* webpackChunkName: "landing.vendors" */
        /* webpackMode: "lazy" */
        'google-libphonenumber'
      )
    ]);

    return {
      CursosSidebar,
      BravePopupsScaling,
      phoneNumberLib
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading landing page features:', error);
    throw error;
  }
};

const bodyHasClass = (className: string) => document.body?.classList.contains(className);

if (document.body?.attributes && !('ng-controller' in document.body.attributes)) {
  const isInLandingPage = bodyHasClass('single-product') && isLandingPage();
  // console.log('Is in landing page mode:', isInLandingPage);

  // Landing page specific code
  if (isInLandingPage) {
    // Load landing page features only when needed
    loadLandingPageFeatures().then(({ CursosSidebar, BravePopupsScaling }) => {
      const sidebarContainer = document.querySelector('#tt-cursos-sidebar');
      if (sidebarContainer) {
        const sidebarRoot = createRoot(sidebarContainer);
        sidebarRoot.render(
          <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
              <CursosSidebar />
            </Suspense>
          </ErrorBoundary>
        );
      }

      // Handle Brave popups scaling
      const bravePopupsContainer = document.querySelector('.ct-inner-content');
      if (bravePopupsContainer && document.querySelector('.bravepopup_embedded')) {
        const scaleContainer = document.createElement('div');
        scaleContainer.className = 'brave-popups-scale-container';
        bravePopupsContainer.appendChild(scaleContainer);

        const braveRoot = createRoot(scaleContainer);
        braveRoot.render(
          <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
              <BravePopupsScaling />
            </Suspense>
          </ErrorBoundary>
        );
      }
    });
  }

  // Single Post
  if (bodyHasClass("single-post")) {
    const singleContainer = document.querySelector(".tt-single") as HTMLElement;
    const singleRoot = createRoot(singleContainer);
    singleRoot.render(
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Single />
        </Suspense>
      </ErrorBoundary>
    );
  }

  // Archive
  if (bodyHasClass("archive") || bodyHasClass("search") || bodyHasClass("blog")) {
    const archiveContainer = document.querySelector(".tt-archive") as HTMLElement;
    const archiveRoot = createRoot(archiveContainer);
    archiveRoot.render(
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Archive />
        </Suspense>
      </ErrorBoundary>
    );
  }
}
