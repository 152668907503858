import { useState, useEffect } from 'react';

export const isLandingPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const landingPageMode = urlParams.get('lp') === '1';
  return landingPageMode;
};

export const useLandingPageMode = () => {
  const [isLandingPageMode, setIsLandingPageMode] = useState(false);

  useEffect(() => {
    const landingPageMode = isLandingPage();
    setIsLandingPageMode(landingPageMode);

    if (landingPageMode) {
      document.body.classList.add('landing-page-mode');
    } else {
      document.body.classList.remove('landing-page-mode');
    }

    return () => {
      document.body.classList.remove('landing-page-mode');
    };
  }, []);

  return isLandingPageMode;
};
