import React, { useContext } from 'react';
import Balancer from 'react-wrap-balancer';
import { PostType } from '../../interfaces';
import { oxygenTtamayoContext, appStateContext } from '../../context';
import { BackgroundImage } from './BackgroundImage';

export const FeaturedImage = ({
  data,
  index,
  width,
  setFrameColor,
  frameColor,
  hasFrame,
  frameDarken,
}: {
  data: PostType;
  index: number;
  width: number;
  frameColor?: string;
  hasFrame?: boolean;
  frameDarken?: number;
  setFrameColor: (color: string | undefined) => void;
}) => {
  const { site_url, category_base } = useContext(oxygenTtamayoContext);
  const { isDevelopment } = useContext(appStateContext);

  return (
    <BackgroundImage
      srcset={data.featured_image_srcset ? data.featured_image_srcset : undefined}
      defaultBackgroundImg={{
        url: data.featured_image_full_size?.url ?? '',
        width: data.featured_image_full_size?.width ?? 0,
      }}
      placeholder={ data.featured_image_lqip ? data.featured_image_lqip : undefined }
      defaultWidth={width}
      className="tt-single__featured-image-outer-container"
      innerContainerClasses='tt-single__featured-image-inner-container'
      backgroundGradient={!hasFrame ? `linear-gradient(0deg, rgba(0,0,0,0.60) 0%, rgba(0,0,0,0.60) 100%)` : undefined}
      innerContainerStyle={hasFrame ? {
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%)`,
        backgroundSize: 'cover',
        boxShadow: [
          `300px 0 200px -50px ${frameColor} inset`,
          `-300px 0 200px -50px ${frameColor} inset`,
        ].join(','),
      } : {
        boxShadow: 'inset 0 -1vh 10vh 0 rgb(0 0 0 / 50%)'
      }}
      setFrameColor={setFrameColor}
      frameDarken={frameDarken}
      hasFrame={hasFrame}
      deCorsify={isDevelopment}
    >
      <div className="tt-single__featured-image-text-container text-center py-12 md:py-28 px-4 md:px-8 lg:px-10 2xl:px-0 w-full text-white">
        <div className="tt-single__categories text-center">
          {data.categories.map((category, i) => {
            return (
              <a
                key={"category-" + category.term_id}
                className={
                  [
                    'tt-single__category',
                    'inline-block',
                    'lg:bg-transparent',
                    'lg:border',
                    'lg:border-white',
                    'lg:hover:border-black',
                    'lg:border-solid',
                    'lg:rounded',
                    'px-2',
                    'py-1',
                    'underline',
                    'hover:underline',
                    'md:hover:no-underline',
                    'lg:no-underline',
                    'text-xs',
                    'font-normal',
                    'mx-1',
                    'lg:mx-2',
                    'opacity-80',
                    'lg:opacity-60',
                    'hover:opacity-100',
                    'mb-2',
                    'text-white',
                    'md:hover:bg-black',
                    'md:hover:text-white',
                    'transition',
                    'duration-200',
                    'ease-in-out',
                  ].join(' ')}
                href={`${site_url}/${category_base}/${category.slug}`}
              >
                {category.name}
              </a>
            )
          })}
        </div>
        <h1 className="tt-single__title mt-4 lg:mt-10 text-white break-words opacity-95">
          <Balancer ratio={0.65}>
            {data.title}
          </Balancer>
        </h1>
        {/* {data.has_excerpt && (
          <div className="tt-single__excerpt text-center" dangerouslySetInnerHTML={{ __html: data.excerpt }} />
        )} */}
        <div className="tt-single__meta-container text-center text-xs md:text-sm opacity-70 hover:opacity-100 italic">
          <div className="tt-single__meta mt-8">
            <span className="tt-single__meta__author__label">Por:</span> <span className="tt-single__meta__author__name"><a className='text-white hover:underline' href={data.post_author_link}>{data.post_author_name}</a></span><br/><span className="tt-single__meta__date__value">{data.date}</span>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};