import React, { FC } from "react";

type HeaderRowProps = {
  className?: string;
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
};

export const HeaderRow: FC<HeaderRowProps> = (props) => {
  return (
    <div className={`tt-header__row ${props.className ?? ''}`}>
      <div className="tt-header__container">
        <div className="tt-header__left">
          {props.left}
        </div>
        <div className="tt-header__center">
          {props.center}
        </div>
        <div className="tt-header__right">
          {props.right}
        </div>
      </div>
    </div>
  );
};
