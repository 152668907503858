import { useEffect } from 'react';

export const useBravePopupsScaling = () => {
  useEffect(() => {
    // Set the scrollbar width as a CSS custom property once on mount
    const SCROLLBAR_WIDTH = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${SCROLLBAR_WIDTH}px`);

    const scaleBravePopups = () => {
      requestAnimationFrame(() => {
        const viewportWidth = window.innerWidth;

        // No scaling needed outside our target range
        if (viewportWidth <= 992 || viewportWidth >= 1360) {
          document.querySelectorAll('.ct-shortcode > .bravepopup_embedded .brave_popup__step__desktop').forEach(popup => {
            (popup as HTMLElement).style.transform = '';
            const popupContainer = popup.parentElement as HTMLElement;
            if (popupContainer) {
              popupContainer.style.height = '';
            }
          });
          return;
        }

        document.querySelectorAll('.ct-shortcode > .bravepopup_embedded .brave_popup__step__desktop').forEach(popup => {
          const inner = popup.querySelector('.brave_popup__step__inner') as HTMLElement;
          const popupContainer = popup.parentElement as HTMLElement;
          if (!inner || !popupContainer) return;

          // Calculate target width based on viewport
          const minWidth = 620;
          const maxWidth = 970;
          const range = 1360 - 992;
          const current = viewportWidth - 992;
          const targetWidth = minWidth + ((maxWidth - minWidth) * (current / range));

          const scale = popupContainer.offsetWidth / targetWidth;

          // Apply transform
          (popup as HTMLElement).style.transform = `scale(${scale})`;

          // Adjust container height
          const originalHeight = inner.offsetHeight;
          if (popupContainer) {
            popupContainer.style.height = `${originalHeight * scale}px`;
          }
        });
      });
    };

    // Initial scale
    scaleBravePopups();

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.some(entry => entry.contentRect.width > 0)) {
        scaleBravePopups();
      }
    });

    const container = document.querySelector('.ct-inner-content');
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
      resizeObserver.disconnect();
    };
  }, []);
};

export default useBravePopupsScaling;
