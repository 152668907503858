import React from 'react';
import { SplitPostData } from '../../interfaces';
import { FaTags } from '@react-icons/all-files/fa/FaTags';

export const LowerMeta = ({data}: {
  data: SplitPostData | undefined
}) => {

  return (
    <div>
      {data && data.tags &&
        <p className='text-gray-500 mt-8 lg:mt-10 text-base'>
          <FaTags
            className='relative font-lg'
            style={{
              top: '2px'
            }}
          />
          <span className='ml-2 mr-1'>Etiquetas: </span>
          {data.tags.map((tag, index) => {
            return (
              <span key={tag.term_id} className="mr-1">
                <a
                  href='{tag.link}'
                  className={[
                    "no-underline",
                    "hover:underline",
                    "lowercase",
                    "text-pink-900",
                    "leading-relaxed"
                  ].join(' ')}
                >
                  {tag.name}
                </a>
                {data.tags && index < data.tags.length - 1 &&
                  <span className=''>, </span>
                }
              </span>
            )
          })}
        </p>
      }
    </div>
  );
};