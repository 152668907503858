import { createContext } from "react";
import {
  oxygenTtamayo,
  loadedImage,
  loadedFont,
  enteredSinglePostItem,
  seenSinglePostItem,
} from "./interfaces";

declare global {
  interface Window {
    oxygenTtamayo: oxygenTtamayo;
  }
}

export const oxygenTtamayoContext = createContext<oxygenTtamayo>(window.oxygenTtamayo);

interface appState {
  loadedImages: loadedImage[];
  loadedFonts: loadedFont[];
  enteredSinglePostItems: enteredSinglePostItem[];
  seenSinglePostItems: seenSinglePostItem[];
  isDevelopment: boolean;
}

const isDevelopment = window.location.hostname !== 'www.ttamayo.com';

// make another context for app state
export const appStateContext = createContext<appState>({
  loadedImages: [],
  loadedFonts: [],
  enteredSinglePostItems: [],
  seenSinglePostItems: [],
  isDevelopment,
});