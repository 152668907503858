/* eslint-disable react/jsx-no-bind */
import React, { FC, useState } from 'react';
import { Input, InputProps } from '@nextui-org/react';
import { SendButton } from './SendButton';
import { BiSearchAlt2 } from '@react-icons/all-files/bi/BiSearchAlt2';

export interface SearchInputProps extends Partial<InputProps> {
  searchValue: string;
  handleSearchChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<SearchInputProps> = ({
  searchValue,
  handleSearchChange,
  ...props
}) => {
  const [searchInputIsError, setSearchInputIsError] = useState<boolean>(false);

  const handleSearch = () => {
    if (searchValue) {
      window.location.href = `/?s=${searchValue}`;
    } else {
      setSearchInputIsError(true);
    }
  }

  const handleSearchKeyDown = (evt: React.KeyboardEvent) => {
    if (searchValue) {
      setSearchInputIsError(false);
    }
    if (evt.key === "Enter") {
      handleSearch();
    }
  }

  return (
    <Input
      {...props}
      clearable
      type="search"
      aria-label='Search'
      onKeyDown={handleSearchKeyDown}
      onChange={handleSearchChange}
      value={searchValue}
      placeholder="Buscar en el blog..."
      contentRightStyling={false}
      status={searchInputIsError ? 'error' : undefined}
      contentRight={
        <SendButton onClick={handleSearch}>
          <BiSearchAlt2 fill="var(--nextui-colors-secondary)" size={16} />
        </SendButton>
      }
    />
  )
}