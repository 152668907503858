/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-console */
import { useContext } from 'react';
import { oxygenTtamayoContext } from '../context';

interface ConversionEvent {
  formData: any;
  eventName: string;
  value?: number;
}

const extractNumericPrice = (priceString: string, recurrencia?: string, duracion?: string): number => {
  const numericString = priceString.replace(/[^0-9.]/g, '');
  const basePrice = parseFloat(numericString) || 0;

  if (recurrencia === 'Costo mensual' && duracion) {
    const months = parseInt(duracion.replace(/\D/g, ''), 10) || 1;
    return basePrice * months;
  }

  return basePrice;
};

// Temporary debug flag - will be replaced with environment variable later
const DEBUG_TRACKING = false;

export const useTrackingEvents = () => {
  const { cursoData } = useContext(oxygenTtamayoContext);

  const getAllTrackingParameters = () => ({
    gclid: new URLSearchParams(window.location.search).get('gclid') ?? '',
    msclkid: new URLSearchParams(window.location.search).get('msclkid') ?? '',
    fb_event_id: `${Date.now()}_${Math.random().toString(36).substring(2, 11)}`,
  });

  const trackConversion = async ({ formData, eventName, value = 0 }: ConversionEvent) => {
    try {
      const coursePrice = extractNumericPrice(
        cursoData?.price ?? '0',
        cursoData?.recurrencia,
        cursoData?.duracion
      );
      const eventValue = value || coursePrice;
      const eventData = {
        content_name: formData.product_name,
        content_category: cursoData?.product_category,
        content_type: 'product',
        currency: formData.currency || 'MXN',
        value: eventValue,
        product_id: formData.product_id?.toString(),
      };

      if (DEBUG_TRACKING) {
        console.log('🔍 Tracking Status:', {
          facebook: typeof window.fbq === 'function',
          tiktok: Boolean(window.ttq?.track),
          microsoft: typeof window.uetq !== 'undefined',
          pinterest: typeof window.pintrk === 'function',
          dataLayer: Boolean(window.dataLayer),
        });
      }

      // Push to dataLayer for GTM/GA4
      if (window.dataLayer) {
        DEBUG_TRACKING && console.log('📊 Pushing to dataLayer:', {
          event: 'form_submission',
          form_name: 'curso_registration',
          ...eventData,
        });
        window.dataLayer.push({
          event: 'form_submission',
          form_name: 'curso_registration',
          form_id: 'curso_enrollment_form',
          form_type: 'lead_generation',
          ...eventData,
          // Additional GA4 recommended parameters
          currency: formData.currency || 'MXN',
          value: eventValue,
          items: [{
            item_id: formData.product_id?.toString(),
            item_name: formData.product_name,
            item_category: cursoData?.product_category,
            price: eventValue,
            currency: formData.currency || 'MXN',
            quantity: 1
          }]
        });
      } else {
        DEBUG_TRACKING && console.warn('⚠️ dataLayer not found - GTM events will not be tracked');
      }

      // Meta Pixel (Facebook)
      if (typeof window.fbq === 'function') {
        DEBUG_TRACKING && console.log('📘 Triggering Facebook tracking event:', eventName, eventData);
        window.fbq('track', 'Lead', {
          ...eventData,
          fb_event_id: formData.fb_event_id,
        });
      } else {
        DEBUG_TRACKING && console.warn('⚠️ Facebook tracking object not found');
      }

      // TikTok Pixel
      if (window.ttq?.track) {
        DEBUG_TRACKING && console.log('🎯 Triggering TikTok tracking event:', eventName, eventData);
        window.ttq.track('SubmitForm', eventData);
      } else {
        DEBUG_TRACKING && console.warn('⚠️ TikTok tracking object not found');
      }

      // Microsoft Ads (Bing)
      if (typeof window.uetq !== 'undefined' && formData.msclkid) {
        DEBUG_TRACKING && console.log('🔵 Triggering Microsoft Ads tracking event:', eventName, eventData);
        window.uetq.push('event', 'generate_lead', eventData);
      } else {
        DEBUG_TRACKING && console.warn('⚠️ Microsoft Ads tracking object not found');
      }

      // Pinterest (if available)
      if (typeof window.pintrk === 'function') {
        DEBUG_TRACKING && console.log('📌 Triggering Pinterest tracking event:', eventName, eventData);
        window.pintrk('track', 'lead', {
          ...eventData,
          lead_type: 'Curso Registration',
        });
      } else {
        DEBUG_TRACKING && console.warn('⚠️ Pinterest tracking object not found');
      }

    } catch (error) {
      DEBUG_TRACKING && console.error('❌ Error tracking conversion:', error);
    }
  };

  return { trackConversion, getAllTrackingParameters };
};
